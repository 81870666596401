<template>
  <div class="client-notes">
    <div v-if="!isLoading && templateOptions && note">
      <div class="d-flex noPrint flex-wrap" v-if="!previewOnly">
        <div class="col-12 col-sm-6 noPrint">
          <select-control
            type="vertical"
            v-model="note.form_template"
            :options="templateOptions"
            required
            inputClass="light-shadow"
            @input="updateAutoFillData"
            >Form Subject</select-control
          >
        </div>
        <div class="col-12 col-sm-6 noPrint">
          <select-control
            type="vertical"
            v-model="note.status"
            :options="statusOptions"
            inputClass="light-shadow"
            required
            >Form Status</select-control
          >
        </div>
      </div>
      <div class="d-flex justify-content-end flex-wrap">
        <div
          class="form-group pl-3 pr-3 col-sm-6 col-12 noPrint"
          v-if="!previewOnly"
        >
          <label class="mb-0">Form Title</label>
          <input
            type="text"
            class="form-control light-shadow"
            v-model="note.name"
            required
          />
        </div>
        <div class="form-group pl-3 pr-3 col-sm-6 col-12 noPrint">
          <select-control
            type="vertical"
            v-model="selectedAptId"
            :options="patientAptsOptions"
            @input="updateSelectedApt"
            inputClass="light-shadow"
            >Event</select-control
          >
        </div>
        <div
          class="
            form-group
            pl-3
            pr-3
            col-sm-6 col-12
            noPrint
            d-flex
            justify-content-end
            align-items-end
          "
        >
          <button
            v-if="previewOnly && user && (user.isAdmin || user.isManagingAdmin)"
            @click="copyShareableLink(note)"
            class="btn btn-lg btn-primary noPrint- mr-3"
            :disabled="generateShareableLinkLoading"
          >
            <span
              class="spinner-border spinner-border-sm"
              v-if="generateShareableLinkLoading"
            ></span>
            <span v-else> <icon type="link" /> Share </span>
          </button>
          <button
            type="button"
            class="btn btn-lg btn-danger noPrint"
            @click="print"
          >
            <i class="fas fa-print mr-2"></i>Print
          </button>
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-lg px-1"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fas fa-cog"></i>
            </button>
            <div
              class="dropdown-menu print-menu"
              @click="$event.stopPropagation()"
            >
              <h3 class="mx-3 my-2">
                <i class="fas fa-cog mr-2"></i>Print settings
              </h3>
              <div class="dropdown-divider"></div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_second_logo_in_report"
                    v-model="printSettings.show_second_logo_in_report"
                    @change="logoReady = false"
                  />
                  <label
                    class="custom-control-label"
                    for="show_second_logo_in_report"
                    >Second Logo</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_name_in_report"
                    v-model="printSettings.show_name_in_report"
                  />
                  <label class="custom-control-label" for="show_name_in_report"
                    >Name</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_email_in_report"
                    v-model="printSettings.show_email_in_report"
                  />
                  <label class="custom-control-label" for="show_email_in_report"
                    >Email</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_location_in_report"
                    v-model="printSettings.show_location_in_report"
                  />
                  <label
                    class="custom-control-label"
                    for="show_location_in_report"
                    >Location</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_phone_number_in_report"
                    v-model="printSettings.show_phone_number_in_report"
                  />
                  <label
                    class="custom-control-label"
                    for="show_phone_number_in_report"
                    >Phone Number</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_claim_number_in_report"
                    v-model="printSettings.show_claim_number_in_report"
                  />
                  <label
                    class="custom-control-label"
                    for="show_claim_number_in_report"
                    >Claim Number</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_date_of_loss_in_report"
                    v-model="printSettings.show_date_of_loss_in_report"
                  />
                  <label
                    class="custom-control-label"
                    for="show_date_of_loss_in_report"
                    >Date of Loss</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_date_of_birth_in_report"
                    v-model="printSettings.show_date_of_birth_in_report"
                  />
                  <label
                    class="custom-control-label"
                    for="show_date_of_birth_in_report"
                    >Date of Birth</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_insurer_in_report"
                    v-model="printSettings.show_insurer_in_report"
                  />
                  <label
                    class="custom-control-label"
                    for="show_insurer_in_report"
                    >Insurer</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_funding_source_in_report"
                    v-model="printSettings.show_funding_source_in_report"
                  />
                  <label
                    class="custom-control-label"
                    for="show_funding_source_in_report"
                    >Fund Source</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_lawyer_in_report"
                    v-model="printSettings.show_lawyer_in_report"
                  />
                  <label
                    class="custom-control-label"
                    for="show_lawyer_in_report"
                    >lawyer</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="print-section client-note-print-section py-4 px-2"
        :class="previewOnly ? 'preview-only' : ''"
      >
        <table style="width: 100%" class="avoid-break-before">
          <thead>
            <tr>
              <td>
                <div class="header-space">&nbsp;</div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="table-content">
                  <div
                    class="
                      header
                      d-flex
                      flex-column
                      justify-content-center
                      align-items-center
                    "
                  >
                    <img
                      v-if="
                        companyProfile.logo ||
                        (printSettings.show_second_logo_in_report &&
                          companyProfile.second_logo)
                      "
                      class="company-logo"
                      :src="
                        printSettings.show_second_logo_in_report &&
                        companyProfile.second_logo
                          ? companyProfile.second_logo
                          : companyProfile.logo
                      "
                      @load="logoLoaded"
                    />
                    <h2 class="title mt-3 mb-0 text-center text-capitalize">
                      {{ note.name }}
                    </h2>
                  </div>
                  <FormRenderer
                    v-if="configuration"
                    :key="note.id"
                    class="col-12"
                    :baseURL="baseUrl"
                    :form-configuration="configuration"
                    v-model="formInputData"
                  />
                  <div class="footer mt-5">
                    <div class="d-flex justify-content-between">
                      <div>
                        <p class="m-0">{{ companyProfile.name }}</p>
                        <p
                          class="m-0"
                          v-if="printSettings.show_email_in_report"
                        >
                          {{ companyProfile.email }}
                        </p>
                        <p
                          class="m-0"
                          v-if="printSettings.show_location_in_report"
                        >
                          {{ companyProfile.location }}
                        </p>
                      </div>
                      <div>
                        <p
                          class="m-0"
                          v-if="printSettings.show_phone_number_in_report"
                        >
                          {{ companyProfile.phone_number }}
                        </p>
                        <p
                          class="m-0"
                          v-if="printSettings.show_lawyer_in_report"
                        >
                          {{ companyProfile.lawyer }}
                        </p>
                      </div>
                    </div>
                    <div
                      class="m-0 w-100 mt-4 text-center h5 text-danger"
                      style="display: none"
                    >
                      {{ (note.data || {}).timeZone || "" }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>
                <div class="footer-space">&nbsp;</div>
              </td>
            </tr>
          </tfoot>
        </table>
        <div class="note-header">
          <div class="row m-0">
            <div class="col-6">
              <p class="mb-0">{{ (selectedTemplate || {}).label }}</p>
              <p class="mb-0" v-if="printSettings.show_name_in_report">
                {{ patient.name }}
              </p>
              <p class="mb-0" v-if="printSettings.show_insurer_in_report">
                Insurer: {{ patient.insurer }}
              </p>
              <p class="mb-0" v-if="printSettings.show_claim_number_in_report">
                Claim Number: {{ patient.claim_number }}
              </p>
            </div>
            <div class="col-6 text-right">
              <p class="mb-0">
                Date of Report:
                {{
                  (formInputData || {}).sessionStartDate | date("MMMM DD, YYYY")
                }}
              </p>
              <p class="mb-0" v-if="printSettings.show_date_of_loss_in_report">
                Date of Loss: {{ patient.injured_at | date("MMMM DD, YYYY") }}
              </p>
              <p class="mb-0" v-if="printSettings.show_date_of_birth_in_report">
                Date of Birth:
                {{ (patient.user || {}).birth_date | date("MMMM DD, YYYY") }}
              </p>
            </div>
          </div>
          <div></div>
        </div>
        <div class="note-footer text-center">Footer</div>
      </div>
      <div class="justify-content-center mt-4 d-flex noPrint">
        <button
          v-if="!previewOnly"
          :disabled="submitting"
          type="button"
          class="btn btn-lg btn-primary mr-3 noPrint"
          @click="EditNote"
        >
          {{ submitting ? "Saving" : "Save" }}
        </button>
        <button
          v-if="previewOnly && user && (user.isAdmin || user.isManagingAdmin)"
          @click="copyShareableLink(note)"
          class="btn btn-lg btn-primary noPrint mr-3"
          :disabled="generateShareableLinkLoading"
        >
          <span
            class="spinner-border spinner-border-sm"
            v-if="generateShareableLinkLoading"
          ></span>
          <span v-else> <icon type="link" /> Share </span>
        </button>
        <button
          type="button"
          class="btn btn-lg btn-danger noPrint"
          @click="print"
        >
          <i class="fas fa-print mr-2"></i>Print
        </button>
        <div class="btn-group">
          <button
            type="button"
            class="btn btn-lg px-1"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fas fa-cog"></i>
          </button>
          <div
            class="dropdown-menu print-menu"
            @click="$event.stopPropagation()"
          >
            <h3 class="mx-3 my-2">
              <i class="fas fa-cog mr-2"></i>Print settings
            </h3>
            <div class="dropdown-divider"></div>
            <div class="dropdown-item">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="show_name_in_report"
                  v-model="printSettings.show_name_in_report"
                />
                <label class="custom-control-label" for="show_name_in_report"
                  >Name</label
                >
              </div>
            </div>
            <div class="dropdown-item">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="show_email_in_report"
                  v-model="printSettings.show_email_in_report"
                />
                <label class="custom-control-label" for="show_email_in_report"
                  >Email</label
                >
              </div>
            </div>
            <div class="dropdown-item">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="show_location_in_report"
                  v-model="printSettings.show_location_in_report"
                />
                <label
                  class="custom-control-label"
                  for="show_location_in_report"
                  >Location</label
                >
              </div>
            </div>
            <div class="dropdown-item">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="show_phone_number_in_report"
                  v-model="printSettings.show_phone_number_in_report"
                />
                <label
                  class="custom-control-label"
                  for="show_phone_number_in_report"
                  >Phone Number</label
                >
              </div>
            </div>
            <div class="dropdown-item">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="show_claim_number_in_report"
                  v-model="printSettings.show_claim_number_in_report"
                />
                <label
                  class="custom-control-label"
                  for="show_claim_number_in_report"
                  >Claim Number</label
                >
              </div>
            </div>
            <div class="dropdown-item">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="show_date_of_loss_in_report"
                  v-model="printSettings.show_date_of_loss_in_report"
                />
                <label
                  class="custom-control-label"
                  for="show_date_of_loss_in_report"
                  >Date of Loss</label
                >
              </div>
            </div>
            <div class="dropdown-item">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="show_date_of_birth_in_report"
                  v-model="printSettings.show_date_of_birth_in_report"
                />
                <label
                  class="custom-control-label"
                  for="show_date_of_birth_in_report"
                  >Date of Birth</label
                >
              </div>
            </div>
            <div class="dropdown-item">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="show_insurer_in_report"
                  v-model="printSettings.show_insurer_in_report"
                />
                <label class="custom-control-label" for="show_insurer_in_report"
                  >Insurer</label
                >
              </div>
            </div>
            <div class="dropdown-item">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="show_funding_source_in_report"
                  v-model="printSettings.show_funding_source_in_report"
                />
                <label
                  class="custom-control-label"
                  for="show_funding_source_in_report"
                  >Fund Source</label
                >
              </div>
            </div>
            <div class="dropdown-item">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="show_lawyer_in_report"
                  v-model="printSettings.show_lawyer_in_report"
                />
                <label class="custom-control-label" for="show_lawyer_in_report"
                  >lawyer</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <alert v-if="isLoading || !note" />
    <loader class="noPrint" v-if="printWaiting"
      ><b class="mt-4">Preparing note to print...</b></loader
    >
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { debounce } from "lodash";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: "EditPreviewClinicalNote",
  mounted() {
    window.addEventListener("resize", this.updateTextareasHeight);
    this.getPatientApt();
  },
  destroyed() {
    window.removeEventListener("resize", this.updateTextareasHeight);
  },
  created() {
    const eventsToFlush = [
      "renderer.validation_ok",
      "renderer.validation_failed",
    ];

    Object.keys(this.$formEvent._events).forEach((key) => {
      if (
        eventsToFlush.includes(key) &&
        this.$formEvent._events[key].length > 0
      ) {
        this.$formEvent._events[key] = [];
      }
    });

    this.$formEvent.$on("promise_start", this.promiseFired);
    this.$formEvent.$on("promise_end", this.promiseTerminated);
    this.$formEvent.$on("renderer.validation_ok", this.proceedAndSave);
    this.$formEvent.$on("renderer.validation_failed", this.showError);
    this.getCompanyProfile().then((companyData) => {
      if (companyData) {
        this.printSettings = {
          show_name_in_report: companyData.show_name_in_report,
          show_email_in_report: companyData.show_email_in_report,
          show_claim_number_in_report: companyData.show_claim_number_in_report,
          show_date_of_loss_in_report: companyData.show_date_of_loss_in_report,
          show_date_of_birth_in_report:
            companyData.show_date_of_birth_in_report,
          show_insurer_in_report: companyData.show_insurer_in_report,
          show_lawyer_in_report: companyData.show_lawyer_in_report,
          show_location_in_report: companyData.show_location_in_report,
          show_phone_number_in_report: companyData.show_phone_number_in_report,
          show_funding_source_in_report:
            companyData.show_funding_source_in_report,
        };
      }
    });

    this.getTemplates().then((res) => {
      if (res) {
        if (this.user) {
          this.templates = res.filter((template) => template.type != "control");
          this.templates = this.templates.map((template) => {
            let templateString = JSON.stringify(template);
            templateString = templateString
              .replace(
                /http:\/\/\{getProvidersAPI\}/g,
                `${this.baseUrl}providers-basic-info`
              )
              .replace(
                /http:\/\/\{getProvidersAPIForPatient\}/g,
                `${this.baseUrl}providers-basic-info?patient_id=${this.patientId}`
              )
              .replace(
                /http:\/\/\{getManagersAPI\}/g,
                `${this.baseUrl}managers-basic-info`
              );
            return JSON.parse(templateString);
          });
        } else {
          this.templates = [];
        }
        this.getClinicalNoteById(this.noteId).then((res) => {
          if (res) {
            this.note = res;
            this.selectedAptId = res.appointment;
            this.updateAutoFillData();
            setTimeout(() => {
              this.updateAutoFillData();
            }, 1000);
            setTimeout(() => {
              this.updateTextareasHeight();
            }, 300);
          }
        });
        this.getPatientById(this.patientId).then((res) => {
          this.patient = res || {};
        });
      }
    });
  },
  data: () => ({
    templates: [],
    patient: {},
    printSettings: {},
    printWaiting: false,
    promiseQueueCount: 0,
    submitting: false,
    logoReady: false,
    selectedAptId: "-1",
    patientApts: [],
    saved: false,
    statusOptions: [
      { value: "0", label: "Incomplete/Draft" },
      { value: "1", label: "Completed" },
    ],
    note: null,
    formInputData: {},
  }),
  methods: {
    ...mapActions({
      getTemplates: "templates/getTemplates",
      createPatientNote: "templates/CreateClinicalNote",
      getClinicalNoteById: "templates/getClinicalNoteById",
      getPatientById: "templates/getPatientById",
      getCompanyProfile: "settings/getCompanyProfile",
      generateShareableLink: "templates/generateShareableLink",
      getQuestionnaireByAptId: "patients/symptoms/getQuestionnaireByAptId",
    }),
    promiseFired: function () {
      this.promiseQueueCount++;
    },
    updateTextareasHeight: debounce(function () {
      window.$("textarea").each(function () {
        window.$(this).height(60);
        let height = window.$(this).prop("scrollHeight");
        height = height > 60 ? height : 60;
        window.$(this).height(height);
      });
    }, 200),
    promiseTerminated: function () {
      this.promiseQueueCount--;
    },
    showError: function () {
      Swal.fire({
        title: "Error",
        text: "Please fix the errors in the form.",
        icon: "error",
      });
    },
    getPatientApt: function () {
      this.$http
        .get(`patients/${this.patientData.user_id}/appointments`)
        .then((res) => {
          this.isGettingAPts = false;
          if (res && res.data && res.data.data) {
            this.patientApts = res.data.data;
          }
        })
        .catch(() => {
          this.isGettingAPts = false;
        });
    },
    updateSelectedApt: function () {
      const selectedApt = (this.patientApts || []).filter(
        (item) => item.id == this.selectedAptId
      )[0];
      this.handleSelectedApt(Object.assign({}, selectedApt));
      this.handleQuestionnaire(this.selectedAptId);
    },
    handleSelectedApt: function (res) {
      this.note.appointment =
        this.selectedAptId != -1 ? this.selectedAptId : undefined;
      // const subHourlyServices = res.services.filter(
      //   (serv) => serv.parent_service_id && serv.pivot?.unit == "HR"
      // );
      // const totalSubHours = subHourlyServices.reduce(
      //   (sum, { pivot }) => sum + pivot?.quantity,
      //   0
      // );
      const start_time = dayjs(res.start_time).utc();
      let end_time = dayjs(res.end_time)
        // .add(totalSubHours * 60, "minute")
        .utc();

      const startHours = start_time.hour() || 12;
      const endHours = end_time.hour() || 12;
      const startHoursAMPM = startHours > 12 ? startHours - 12 : startHours;
      const endHoursAMPM = endHours > 12 ? endHours - 12 : endHours;
      const aptData = {
        sessionStartDate: start_time.format(),
        sessionEndDate: end_time.format(),
        sessionReason: res.reason,
        sessionMemo: res.memo,
        sessionStatus: res.status,
        sessionType: res.type,
        sessionStartHours:
          startHoursAMPM > 9 ? `${startHoursAMPM}` : `0${startHoursAMPM}`,
        sessionStartMinutes:
          start_time.minute() > 9
            ? `${start_time.minute()}`
            : `0${start_time.minute()}`,
        sessionStartAMPM: startHours > 12 ? "PM" : "AM",
        sessionEndHours:
          endHoursAMPM > 9 ? `${endHoursAMPM}` : `0${endHoursAMPM}`,
        sessionEndMinutes:
          end_time.minute() > 9
            ? `${end_time.minute()}`
            : `0${end_time.minute()}`,
        sessionEndAMPM: endHours > 12 ? "PM" : "AM",
      };
      aptData.sessionStartTime = `${aptData.sessionStartHours}:${aptData.sessionStartMinutes} ${aptData.sessionStartAMPM}`;
      aptData.sessionEndTime = `${aptData.sessionEndHours}:${aptData.sessionEndMinutes} ${aptData.sessionEndAMPM}`;
      if (res.services && res.services.length) {
        const service = res.services[0];
        aptData.serviceDescription = service.description;
        aptData.serviceName = service.name;
        aptData.serviceTaxable = service.taxable;
        aptData.serviceCatastrophic = service.catastrophic;
        aptData.serviceProv = service.prov;
        aptData.serviceRate = service.rate;
        aptData.serviceUnit = service.unit;
      }
      this.formInputData = Object.assign(this.formInputData, aptData);
      // this.formInputData = Object.assign({}, this.autoFilledData);
    },
    handleQuestionnaire: function (appointmentId) {
      this.gettingQuestionnaire = true;
      this.getQuestionnaireByAptId(appointmentId).then((res) => {
        this.gettingQuestionnaire = false;
        if (res) {
          const questionnaireData = {
            frustration: `${res.frustration}`,
            difficulties_with_problem_solving: `${res.difficulties_with_problem_solving}`,
            dizziness: `${res.dizziness}`,
            difficulties_with_sensory_stimuli: `${res.difficulties_with_sensory_stimuli}`,
            memory: `${res.memory}`,
            verbal_aggression: `${res.verbal_aggression}`,
            physical_aggression: `${res.physical_aggression}`,
            socially_inappropriate_behavior: `${res.socially_inappropriate_behavior}`,
            sexually_inappropriate_behavior: `${res.sexually_inappropriate_behavior}`,
            painTime: `${res.time_pain_client}`,
            sleepHours: `${res.hours_client}`,
            anxietyReported: `${res.anxiety_client}`,
            clientPainRate: res.pain_rate_client,
            noClientPain: res.pain_rate_client == -1,
            clientFatigueRate: res.fatigue_rate_client,
            noClientFatigue: res.fatigue_rate_client == -1,
            clientMoodRate: res.mood_rate_client,
            noClientMood: res.mood_rate_client == -1,
            clientHeadachesRate: res.headaches_rate_client,
            noClientHeadaches: res.headaches_rate_client == -1,
            activityCategory: res.activity_categories.map((item) => item.id),
            bodyLocations: res.body_locations,
            providerPainRate: res.pain_rate_provider,
            providerFatigueRate: res.fatigue_rate_provider,
            providerMoodRate: res.mood_rate_provider,
            providerHeadachesRate: res.headaches_rate_provider,
          };

          this.formInputData = Object.assign(
            this.formInputData,
            questionnaireData
          );
          this.formInputData = Object.assign({}, this.formInputData);
        }
      });
    },
    updateAutoFillData: function () {
      setTimeout(() => {
        this.formInputData = this.note?.data;
      }, 100);
    },
    logoLoaded: function () {
      this.logoReady = true;
    },
    EditNote: function () {
      if (!(this.note.name || "").trim()) {
        Swal.fire({
          title: "Error",
          text: "Note title is missing",
          icon: "error",
        });
        return;
      }

      if (this.formInputData.noClientPain) {
        this.formInputData.clientPainRate = -1;
      }
      if (this.formInputData.noClientMood) {
        this.formInputData.clientMoodRate = -1;
      }
      if (this.formInputData.noClientFatigue) {
        this.formInputData.clientFatigueRate = -1;
      }
      if (this.formInputData.noClientHeadaches) {
        this.formInputData.clientHeadachesRate = -1;
      }
      this.$formEvent.$emit("renderer.run_validation");
    },
    proceedAndSave: function () {
      const data = {
        id: this.note.id,
        name: this.note.name,
        patient: this.note.patient,
        user: this.note.user,
        status: this.note.status,
        data: {
          ...this.note.data,
          ...this.formInputData,
        },
        appointment: this.note.appointment,
        form_template: this.note.form_template,
      };
      this.submitting = true;
      this.createPatientNote(data).then((res) => {
        this.submitting = false;
        if (res) {
          this.saved = true;
          this.$router.push({ name: "patients.notes" });
        }
      });
    },
    print: function () {
      this.printWaiting = true;
      const interval = setInterval(() => {
        if (
          (this.logoReady ||
            !this.companyProfile.logo ||
            (this.printSettings.show_second_logo_in_report &&
              !this.companyProfile.second_logo)) &&
          !this.promiseQueueCount
        ) {
          clearInterval(interval);
          this.printWaiting = false;
          window.print();
        }
      }, 500);
    },
    copyShareableLink: async function (note) {
      const { token } = await this.generateShareableLink({
        id: note.id,
      });
      const urlToCopy = `${window.location.origin}/clinical-notes/${token}`;
      navigator.clipboard.writeText(urlToCopy).then(
        function () {
          Swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            icon: "success",
            title: "The shareable link has been copied to your clipboard.",
          });
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.templates.isLoading,
      user: (state) => state.auth.user,
      patientData: (state) => state.patients.patient,
      companyProfile: (state) => state.settings.companyProfile,
      baseUrl: (state) => state.appConfig.api,
      generateShareableLinkLoading: (state) =>
        state.templates.generateShareableLinkLoading,
    }),
    previewOnly: function () {
      return this.$route.query.preview == "true" || (this.note || {}).locked;
    },
    patientId: function () {
      return this.$route.params.id;
    },
    noteId: function () {
      return this.$route.params.noteId;
    },
    configuration: function () {
      if (this.templates && this.templates.length && this.note) {
        const template = this.templates.filter(
          (template) => template.id == this.note.form_template
        )[0];
        return Object.assign({}, template.body);
      }
      return null;
    },
    templateOptions: function () {
      if (this.templates) {
        return this.templates
          .filter((template) => !template.hidden)
          .map((template) => {
            return {
              value: template.id,
              label: template.title,
            };
          });
      }
      return [];
    },
    patientAptsOptions: function () {
      if (this.patientApts.length) {
        const options = this.patientApts.map((apt) => {
          let label = "";
          let serviceName = "";
          if (apt.services.length) {
            serviceName = apt.services[0].name;
          }
          const subHourlyServices = apt.services.filter(
            (serv) => serv.parent_service_id && serv.pivot?.unit == "HR"
          );
          const subServicesHours = subHourlyServices.reduce(
            (sum, { pivot }) => sum + pivot?.quantity,
            0
          );
          const subMinutes =
            (subServicesHours - parseInt(subServicesHours)) * 60;
          label = dayjs(apt.start_time).utc().format("MMM D, YYYY hh:mm A");
          label = `${label} - ${dayjs(apt.end_time)
            .utc()
            .format("MMM D, YYYY hh:mm A")}`;
          if (serviceName) {
            label = `${label} (${serviceName})`;
          }
          if (subServicesHours) {
            label = `${label} +${parseInt(subServicesHours)}h${
              subMinutes > 0 ? " " + subMinutes + "m" : ""
            }`;
          }
          return {
            value: apt.id,
            label,
          };
        });

        options.push({
          value: "-1",
          label: "Select Event",
        });
        return options.reverse();
      }
      return [
        {
          value: "-1",
          label: "Select Event",
        },
      ];
    },
    selectedTemplate: function () {
      return this.templateOptions.filter(
        (item) => item.value == this.note.form_template
      )[0];
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.saved || this.previewOnly) {
      return next();
    }
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-black ml-4",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    return swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "Any changes you made may not be saved.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, leave!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          return next();
        }
        return next(false);
      });
    // called when the route that renders this component is about to be navigated away from.
    // As with `beforeRouteUpdate`, it has access to `this` component instance.
  },
};
</script>
<style scoped lang="scss">
@page {
  size: auto;
  margin: none;
}
.loader {
  left: 0;
  right: 0;
  border: 0;
  background: rgba(255, 255, 255, 0.98);
  top: 0;
  z-index: 9999;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}
.print-menu {
  max-height: 300px;
  overflow-y: auto;
}
.client-note-print-section {
  border: 1px solid #c1c4d0;
  background-color: white;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  border-radius: 12px;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
}
.preview-only * {
  pointer-events: none;
}
.company-logo {
  position: absolute;
  left: 0;
  max-width: 30%;
  max-height: 90%;
}
.title {
  width: 40%;
  margin: 0 auto;
}
.header {
  position: relative;
  border-bottom: 1px solid lightgray;
  padding-bottom: 20px;
  min-height: 150px;
}

.footer {
  color: gray;
  border-top: 1px solid lightgray;
  padding-top: 20px;
}
</style>

<style lang="scss">
.preview-only {
  .tabs ul li {
    pointer-events: all !important;
  }
}
.note-header,
.header-space,
.note-footer,
.footer-space {
  height: 100px;
  display: none;
}
.note-header,
.note-footer {
  position: absolute;
  width: 100%;
  left: 0;
}

.note-header {
  top: 0;
}
.note-footer {
  bottom: 0;
}
.client-note-print-section {
  .col-md-1 {
    select {
      min-width: 60px;
      margin-left: -8px;
    }
  }
}
@media print {
  @page {
    margin: 4mm;
  }
  .main-container {
    margin-top: 0;
  }
  .note-header,
  .header-space {
    display: block;
  }
  .note-footer,
  .footer-space {
    display: none;
  }
  .client-note-print-section {
    padding-left: 0 !important;
    padding-right: 0 !important;
    border: 0 !important;
    background-color: transparent !important;
  }
  .vue-form-renderer .control-view,
  .vue-form-renderer .control-view-wrapper {
    break-inside: avoid;
    page-break-inside: avoid;
    z-index: 9;
  }
}
.table-content {
  page-break-inside: avoid;
}
</style>
